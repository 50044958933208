import { CSSProperties, createElement } from "react";
import style from "@/app/styles/Molecules/WidgetTitle.module.scss";

interface Props {
	title?: string;
	text?: string;
	lineColor?: string;
	areH1?: boolean;
	isDark?: boolean;
}

export default function WidgetTitle({
	title,
	text,
	lineColor,
	areH1,
	isDark,
}: Props) {
	if (!title) {
		return null;
	}

	const heading = areH1 ? "h1" : "h2";

	return (
		<header
			className={`${style["widgetTitle__wrapper"]}`}
			style={
				{
					"--textColor": `${isDark ? "#EDF4F8" : "#474B4DS"}`,
				} as CSSProperties
			}
		>
			<div className={`${style["widgetTitle__wrapperSection"]}`} style={lineColor ? {borderTop: `4px solid ${lineColor}`} : {}}>
				{title &&
					createElement(
						heading,
						{
							className: `${style["widgetTitle__title"]}`,
							"aria-label": `${title}`,
						},
						title
					)}
				{text && (
					<p className={`${style["widgetTitle__text"]}`} aria-label={text}>
						{text}
					</p>
				)}
			</div>
		</header>
	);
}
